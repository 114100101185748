import { privacyPreferencesDetected } from '../helpers';
/**
 * Google Analytics Send Wrapper
 * Use It When You Need to Send Data to GA Loaded via GTM
 * @param {Object} data - data for Google Analytics send() method
 * @param {Object} gaTracker - ID of a Google Analytics tracker (NOT GA-***)
 */
export default function gaSend(data, gaTracker) {
    if (!privacyPreferencesDetected) {
        // New GA
        if (typeof window.ga !== 'undefined') {
            window.ga((gaTracker ? gaTracker + '.' : '') + 'send', data);
        }
        // Old GA
        else if (typeof window._gaq !== 'undefined') {
            // Pageview
            if (data.hitType === 'pageview' && data.page) {
                window._gaq.push([(gaTracker ? gaTracker + '.' : '') + '_trackPageview', data.page]);
            }
            // Event
            if (data.hitType === 'event' && data.eventCategory) {
                let eventConfig = [(gaTracker ? gaTracker + '.' : '') + '_trackEvent'], eventProperties = ['eventCategory', 'eventAction', 'eventLabel'];
                for (let i = 0; i < eventProperties.length; ++i) {
                    if (data[eventProperties[i]]) {
                        eventConfig.push(data[eventProperties[i]]);
                    }
                }
                window._gaq.push(eventConfig);
            }
        }
    }
}
