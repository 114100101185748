import getCookie from "./getCookie";
import { track } from "../trackers";
/**
 * Check Heap cookie for tracking params
 * @returns {String} trackingParamsObject - object list of tracking params
 */
export default function getHeapCookieData() {
    if (window.heap && window.heap.appid) {
        const cookieVal = getCookie('_hp2_props.' + window.heap.appid);
        if (cookieVal) {
            try {
                return JSON.parse(decodeURIComponent(cookieVal));
            }
            catch (e) {
                track('track', ['Heap Cookie Parse Error', {
                        cookieVal
                    }]);
            }
        }
        else {
            console.log('No tracking params available');
        }
    }
    else {
        console.log('Heap is not connected to the page');
    }
}
