import heap from "./heap";
import gaSend from "./gaSend";
/**
 * Track Wrapper
 * @param {String} method - method name
 * @param {Mixed} data - string, array or object property of the method
 * @param {String} [tracker] - if none is provided, defaults to heap
 */
export default function track(method, data, tracker) {
    if (!tracker) {
        heap(method, data);
    }
    else if (tracker === 'ga' || tracker === '_gaq') {
        gaSend(data, tracker);
    }
    // start tracking all of our errors
    if (method === 'error') {
        heap('track', data);
        console.log(data);
    }
}
