/**
 * Gets cookie
 * @param {String} name - cookie's name
 * @returns {Mixed} - cookie's value
 */
export default function getCookie(name) {
    const cookieName = name + '=';
    const cookieData = document.cookie.split(';');
    for (let i = 0; i < cookieData.length; ++i) {
        let c = cookieData[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1, c.length);
        }
        if (~c.indexOf(cookieName)) {
            return c.substring(cookieName.length, c.length);
        }
    }
    return null;
}
