import getHeapCookieData from "./getHeapCookieData";
export default function checkLastSrc(testCookie) {
    const heapCookie = testCookie ? testCookie : getHeapCookieData();
    if (heapCookie) {
        const prevDate = heapCookie.timestamp;
        const currentDate = Date.now();
        const oneDay = 86400000;
        if ((currentDate - prevDate) > oneDay) {
            return true;
        }
    }
    return false;
}
