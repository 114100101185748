import { getCurrentTrade } from "helpers";
/**
 * Get environment of the current page
 * @returns {String} - Default Source for Organic traffic
 */
export default function getDefaultSrc() {
    const seoSrcParamValuesByTrade = {
        general: 'Mod001',
        solar: 'Mod002',
        windows: 'Mod003',
        hvac: 'Mod004',
        roofing: 'Mod005',
        siding: 'Mod006'
    };
    const pageTrade = getCurrentTrade();
    return seoSrcParamValuesByTrade[pageTrade];
}
