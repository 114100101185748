/**
 * Get URL params into key/value object with keys in lowercase
 * @param {String} url - URL to get params (query string) of
 * @param {Boolean} lowercase - If true, lowercase all params
 * @returns {Object} - object of URL params
 */
export default function getUrlParamsToObject(url, lowercase) {
    if (!url) {
        url = window.location.href.replace(window.location.hash, '');
    }
    const querypairs = url.substring(url.indexOf('?') + 1).split('&');
    return querypairs.reduce((prev, next) => {
        const pair = next.split(/=(.+)/);
        const decodedKey = decodeURIComponent(pair[0]);
        const key = (lowercase ? decodedKey.toLowerCase() : decodedKey);
        if (!prev.hasOwnProperty(key)) {
            if (!pair[1]) {
                return prev;
            }
            prev[key] = decodeURIComponent(pair[1].replace(/\+/g, '%20'));
        }
        return prev;
    }, {});
}
