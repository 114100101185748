/**
 * Get environment of the current page
 * @returns {String} - environement (local, staging, qa, or production)
 */
export default function getEnv() {
    const url = document.URL;
    const domain = window.location.hostname;
    const ipAddressRegex = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
    let env = 'production';
    if (~domain.indexOf('.local') || domain === 'localhost' || ~url.indexOf('file://') || ipAddressRegex.test(domain)) {
        env = 'local';
    }
    else if (~domain.indexOf('staging.')) {
        env = 'staging';
    }
    else if (~domain.indexOf('qa.')) {
        env = 'qa';
    }
    else {
        env = 'production';
    }
    if (~url.indexOf('env=qa')) {
        env = 'qa'; // Override environment to qa if env=qa is a query parameter
    }
    else if (~url.indexOf('env=production')) {
        env = 'production';
    }
    return env;
}
