import heap from './heap';

function getDeviceProperties(UA) {
	let deviceName = ' ',
		platform = ' ',
		browser = ' ',
		isWindows = false;

	// Find first parentheses occurrence like- (Linux; Android 8.1.0; SM-T837A)
	deviceName = UA.match(/\(([^)]+)\)/);

	if (deviceName && deviceName.length) {
		let tempDeviceData = [];
		let tempDeviceName = deviceName;
		isWindows = tempDeviceName[1].match(/Win/gi) && tempDeviceName[1].match(/Win/gi).length;
		// Clean the device string by removing unnecessary strings like- U; en-us;
		tempDeviceName[1] = tempDeviceName[1].replace(/;\s\w+:(\w+(?:\.\w+)+)|(\s[a-z|A-Z]{1};)|(\s\w+-\w+;\s)/gi,'');
		tempDeviceData = tempDeviceName[1].split(';');

		if (tempDeviceData.length) {
			if (tempDeviceData.length === 3) {
				tempDeviceName = isWindows ? tempDeviceData[0] : tempDeviceData[2];
			} else if (tempDeviceData[0]) {
				tempDeviceName = tempDeviceData[0];
			}

			if (tempDeviceData.length === 4 && tempDeviceData[3]) {
				platform = tempDeviceData[3];
			} else if (tempDeviceData[1]) {
				platform = tempDeviceData[1];
			}
		}

		if (tempDeviceName && typeof tempDeviceName === 'string') {
			deviceName = tempDeviceName.replace(/[()]|(\w+\/\w.+)/, '');
			deviceName = deviceName.trim();
		}

		if (platform && typeof platform === 'string') {
			platform = platform.replace(/_/g, '.');
			platform = platform.trim();
		}
	}

	// Find all browser strings like Chrome/70.0
	browser = UA.match(/\w+\/(\w+(?:\.\w+)+)/g);

	if (browser && browser.length) {
		let tempBrowser = browser;
		let tempBrowserSlice = [];

		if (tempBrowser.length > 2) {
			// Remove first two indexes that are always be Mozilla/5.0 & AppleWebKit/537.36
			tempBrowserSlice = tempBrowser.slice(2);
			tempBrowser = tempBrowserSlice.join(' ').replace(/Safari\/[\d.]+/i, '');
		} else {
			tempBrowser = tempBrowser[1];
		}

		// For safari browser replace 'Version/<number>' with 'Safari'
		if (tempBrowserSlice.length === 2) {
			let arr1 = [];
			let arr2 = [];
			const versionRgex = /Version/i;

			arr1 = tempBrowserSlice[0].split('/');
			arr2 = tempBrowserSlice[1].split('/');

			if (arr1.length && versionRgex.test(arr1[0])) {
				arr1[0] = arr2[0];
				tempBrowser = arr1.join('/');
			}
			if (arr2.length && versionRgex.test(arr2[0])) {
				arr2[0] = arr1[0];
				tempBrowser = arr2.join('/');
			}
		}

		if (tempBrowser && typeof tempBrowser === 'string') {
			browser = tempBrowser.replaceAll('/', ' ');
			browser = browser.trim();
		}
	}
	return { deviceName, platform, browser };
}

export default function trackUserAgent() {
	const deviceProperties = {};
	if ('navigator' in window && 'userAgent' in navigator) {
		const { deviceName, browser, platform } = getDeviceProperties(navigator.userAgent);
		deviceProperties.device = deviceName;
		deviceProperties.browser = browser;
		deviceProperties.platform = platform;
		deviceProperties.UA = navigator.userAgent;
	}

	heap('track', ['Device Properties', deviceProperties]);
}
