export default function init() {
    (function (w, d, s, l, i) {
        var _a;
        w[l] = w[l] || [];
        w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
        let f = d.getElementsByTagName(s)[0], j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : '';
        j.async = true;
        j.src =
            'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
        (_a = f.parentNode) === null || _a === void 0 ? void 0 : _a.insertBefore(j, f);
    })(window, document, 'script', 'dataLayer', gtm_container_ID);
}
