import { getEnv, getUrlParamsToObject } from '../helpers';
import { getCookie, setCookie } from 'cookies';
/**
 * Heap Wrapper
 * @param {String} method - method name
 * @param {Mixed} data - string, array or object property of the method
 */
export default function heap(method, data) {
    const supportedMethods = ['track', 'identify', 'resetIdentity', 'addUserProperties', 'addEventProperties', 'removeEventProperty', 'clearEventProperties'];
    if (!~supportedMethods.indexOf(method)) {
        console.log('Heap "' + method + '" method is not supported');
        return;
    }
    if (typeof window.heap !== 'undefined') {
        if ('identify' === method || 'track' === method) {
            window.heap[method](data[0], data[1]);
        }
        else {
            window.heap[method](data);
        }
    }
    else if ('production' !== getEnv()) {
        const heapDebugEnabled = getUrlParamsToObject('', true).heapdebug === 'y';
        if (heapDebugEnabled) {
            let qaHeapData = getCookie('qaheap');
            if (qaHeapData) {
                qaHeapData = JSON.parse(qaHeapData);
            }
            else {
                qaHeapData = {};
            }
            console.group(`Heap Analytics | Method ${method} Fired`);
            // Methods with data we need to store
            switch (method) {
                case 'identify':
                    qaHeapData.identity = data;
                    break;
                case 'resetIdentity':
                    delete qaHeapData.identity;
                    break;
                case 'removeEventProperty':
                    if (typeof qaHeapData.allEventProperties !== 'undefined' && data[0] in qaHeapData.allEventProperties) {
                        delete qaHeapData.allEventProperties[data[0]];
                    }
                    break;
                case 'clearEventProperties':
                    if ('allEventProperties' in qaHeapData) {
                        delete qaHeapData.allEventProperties;
                    }
                    break;
                case 'addEventProperties':
                    if (typeof data === 'object' && !Array.isArray(data)) {
                        qaHeapData.allEventProperties = (qaHeapData.allEventProperties ? Object.assign(qaHeapData.allEventProperties, data) : data);
                    }
                    else {
                        console.warn('addEventProperties needs a key/value object');
                    }
                    break;
                case 'addUserProperties':
                    if (typeof data === 'object' && !Array.isArray(data)) {
                        qaHeapData.userProperties = (qaHeapData.userProperties ? Object.assign(qaHeapData.userProperties, data) : data);
                    }
                    else {
                        console.warn('addUserProperties needs a key/value object');
                    }
                    break;
            }
            // User Info logs
            console.group('User Info');
            console.log('Identity: ', qaHeapData.identity);
            // User Properties logs
            if (qaHeapData.userProperties) {
                console.group('User Properties');
                for (const [property, value] of Object.entries(qaHeapData.userProperties)) {
                    console.log(`${property}: `, value);
                }
                console.groupEnd();
            }
            console.groupEnd();
            if (method === 'track') {
                console.group('Custom Event Info');
                console.log('Custom  Event Name: ', data[0]);
                // Specific Custom Event Properties logs
                if (data[1]) {
                    console.group('Custom Event Properties');
                    for (const [property, value] of Object.entries(data[1])) {
                        console.log(`${property}: `, value);
                    }
                    console.groupEnd();
                }
                // All Global Event Properties logs
                if (qaHeapData.allEventProperties) {
                    console.group('Global Custom Event Properties');
                    for (const [property, value] of Object.entries(qaHeapData.allEventProperties)) {
                        console.log(`${property}: `, value);
                    }
                    console.groupEnd();
                }
                console.groupEnd();
            }
            console.groupEnd();
            // Setting cookie that expires in one day
            setCookie('qaheap', JSON.stringify(qaHeapData), 1440);
        }
        else if (sessionStorage.getItem('noHeapWasWarned') === null) {
            console.warn('Heap is not defined!');
            sessionStorage.setItem('noHeapWasWarned', 't');
        }
    }
}
