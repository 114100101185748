/**
 * Get current page trade
 * @param {String} pageUrl - page url
 * @returns {String} - trade
 */
export default function getCurrentPageTrade() {
    let trade = null;
    const urlSubstringToTrade = {
        window: 'windows',
        solar: 'solar',
        hvac: 'hvac',
        roof: 'roofing',
        siding: 'siding'
    };
    const pageUrl = window.location.pathname.split('/');
    outerLoop: for (let i = 1; i < pageUrl.length; i++) {
        for (const substr in urlSubstringToTrade) {
            if (urlSubstringToTrade.hasOwnProperty(substr)) {
                if (pageUrl[i].indexOf(substr) > -1) {
                    trade = urlSubstringToTrade[substr];
                    break outerLoop;
                }
                else {
                    trade = 'general';
                }
            }
        }
    }
    return trade;
}
